<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DiningMixins from '@/modules/product/dining/mixins/DiningMixins';
import DiningDetailsMixins from '@/modules/product/dining/mixins/DiningDetailsMixins';
import FlightRedirectMixins from '@/modules/flights/mixins/FlightRedirectMixins';
import * as Sentry from '@sentry/vue';

export default {
  name: 'dining-page',

  mixins: [DiningMixins, DiningDetailsMixins, FlightRedirectMixins],

  data() {
    return {
      fromRoute: null,
    };
  },

  created() {
    Sentry.setTag('module', 'dining');
  },

  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.fromRoute = from;
    });
  },
};
</script>

<style lang="scss" scoped>
.error-banner {
  background: transparent;
  z-index: 999;
}
.container-margin {
  padding-left: 140px;
  padding-right: 140px;
}
</style>
