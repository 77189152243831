<script>
export default {
  name: 'DiningMixins',
  methods: {
    searchDining({
      city,
      lng,
      tags,
      lat,
      minRating,
      minPriceScale,
      maxPriceScale,
      radius,
      skip,
      take,
    }) {
      let latitude;
      let longitude;
      if (lat) {
        latitude = lat;
      } else {
        latitude = '40.7128';
      }

      if (lng) {
        longitude = lng;
      } else {
        longitude = '-74.0060';
      }
      this.$store.dispatch('dining/searchDining', {
        city: city,
        latitude: latitude,
        longitude: longitude,
        tags: tags,
        minRating: minRating,
        minPriceScale: minPriceScale,
        maxPriceScale: maxPriceScale,
        radius: radius,
        skip: skip,
        take: take,
      });
    },
    clearDining() {
      this.$store.dispatch('dining/clearDinnerSearch');
    },
    performCitySearch(location) {
      this.$store.dispatch('dining/performCitySearch', location);
    },
    async performReverseGeocode({ lat, lng }) {
      await this.$store.dispatch(`dining/performReverseGeocode`, {
        lat: lat,
        lng: lng,
      });
    },
    updateCenterCoordinates(coordinates) {
      this.$store.dispatch(`dining/updateCenterCoordinates`, coordinates);
    },
  },
  computed: {
    hasDiningSearch() {
      return this.$store.getters[`dining/hasDiningSearch`];
    },
    hasCitySearch() {
      return this.$store.getters[`dining/hasCitySearch`];
    },
    diningSearchError() {
      if (!!this.diningSearch.errorResponse)
        return this.diningSearch.errorResponse;
      else return null;
    },
    diningSearch() {
      return this.$store.state['dining'].diningSearch;
    },
    citySearch() {
      return this.$store.state['dining'].citySearch;
    },
    reverseGeocode() {
      return this.$store.state['dining'].reverseGeocode;
    },
    updatedCenterCoordinates() {
      return this.$store.state['dining'].updateCenterCoordinates.value;
    },
    userLocation() {
      return this.$store.state.userLocation;
    },
  },
};
</script>
